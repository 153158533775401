import utilities from './utilities';
import store from '../configureStore';

const axios = require('axios');
//var baseConfig={"headers":{"Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"}}
let oauthConfig = {"headers":{"accesstoken":localStorage.getItem('access_token')}}
const token = localStorage.getItem('access_token');

export const restUtils = {

  getData: url => {
    let oauthConfig = {"headers":{Authorization:`Bearer ${token}`}}
    let config = {...oauthConfig, method: 'get', url: url };
    return restUtils.processRequest(config);
  },

  postData: (url, data) => {
    let oauthConfig = {"headers":{"accesstoken":localStorage.getItem('access_token')}}
    // const proxy = require('http-proxy-middleware');
    let config = {...oauthConfig, method: 'post', url: url, data: data};
    return restUtils.processRequest(config);
  },

  
  putData: (url, data) => {
    let oauthConfig = {"headers":{"accesstoken":localStorage.getItem('access_token')}}
    let config = { ...oauthConfig, method: 'put', url: url, data: data };
    return restUtils.processRequest(config);
  },
  
  deleteData: (url, data) => {
    let oauthConfig = {"headers":{"accesstoken":localStorage.getItem('access_token')}}
    let config = { ...oauthConfig, method: "delete", url: url};
    if(data) {
      config.data = data;
    }
    return restUtils.processRequest(config);
  },

  getDataWithoutToken: url => {    
    let config = {method: 'get', url: url };
    return restUtils.processRequest(config);
  },

  postDataWithoutToken: (url, data) => {    
    let config = {method: 'post', url: url, data: data};
    return restUtils.processRequest(config);
  },

  putDataWithoutToken: (url, data) => {
    let config = { method: 'put', url: url, data: data };
    return restUtils.processRequest(config);
  },

  deleteDataWithoutToken: (url, data) => {
    let config = { method: "delete", url: url};
    if(data) {
      config.data = data;
    }
    return restUtils.processRequest(config);
  },

  /**
   * config object can have method, url and data and so on. Please refer axios doc for more options
   */
  processRequest: config => {
  
    const secretKey = store.getState().SessionReducer.secretKey;
     if(secretKey){
         utilities.validateToken(secretKey);
     }
   
    return new Promise((resolve, reject) => {
      axios(config)
        .then(response => {
        
          resolve(response);
        })
        .catch(error => {
        
              reject(error);
        });
    });
  }
};

export default restUtils;

import React from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import RenderRoutes from "./views/common/RenderRoutes";
import HeaderTop from "./components/HeaderTop";
import Footer from "./components/Footer";

const App = () => <RenderRoutes />;

export default App;

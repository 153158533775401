import restUtils from '../../utils/restUtils';
import { persistor } from '../../configureStore';
import { loginUrl, JWKS_URI } from '../../urlConstants';
import Axios from 'axios';

export const LOGIN = 'LOGIN_ACTION';
export const AUTHORIZATION = 'AUTHORIZATION';
export const GET_SECRET = 'GET_SECRET';
export const LOGOUT = 'LOGOUT';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_CHILDSTORESELECTED = 'SET_CHILDSTORESELECTED';
export const HIDEREUNIONPOPUP ='HIDEREUNIONPOPUP'
export const SETMENU = 'SETMENU'

export const performLogin = (UserId, Password, StoreId) => {
  return dispatch => {
    const postObj = {
      StoreId,
      UserId,
      Password
    };
    restUtils
      .postData(loginUrl, postObj)
      .then(response => {
        const user = response.data || null;

        if (user) {
          const { UserId, StoreId, address } = user;
          const isAuthenticated = UserId && UserId !== 0;
          const username = (address && address.primaryContactName) || 'Unknown';

          return dispatch({
            type: LOGIN,
            payload: {
              username,
              UserId,
              storeId: StoreId,
              address,
              isAuthenticated
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const setAuthentication = isAuthenticated => ({
  type: SET_AUTHENTICATION,
  payload: {
    isAuthenticated
  }
});

export const setSelectedChildStore = childStoresSelected => ({
    type: SET_CHILDSTORESELECTED,
    payload: {
      childStoresSelected
    }  
});

export const login = (username, UserId, storeId, address, mainRDCUnderEmergency, isAuthenticated, viewOnly, multiStoreDetails) => {
  return dispatch => {
    dispatch({
      type: LOGIN,
      payload: {
        username,
        UserId,
        storeId,
        address,
        mainRDCUnderEmergency,
        isAuthenticated,
        viewOnly,
        multiStoreDetails        
      }
    });
  };
};

export const authorizeUser = (id_token, access_token, expires_at) => ({
  type: AUTHORIZATION,
  payload: {
    id_token,
    access_token,
    expires_at
  }
});

export const getSecretKey = (access_token)=>{
  const jwks_url = JWKS_URI();
  return dispatch => {
    Axios.get(jwks_url)
      .then(response=>{
        dispatch({
          type:GET_SECRET,
          payload:response.data.keys[0].x5c[0]
        })
      })
  }
}
export const logout = () => {
  return dispatch => {
    localStorage.clear();
    persistor.purge().then(() => {
      return dispatch({ type: LOGOUT });
    });
  };
};

export const onlyReunion = () => ({type: HIDEREUNIONPOPUP,payload: false})
export const setEntrieMenu = ((menu)=> {
  return {
   type: SETMENU,
   payload: menu
 }
 })